<template>
  <!-- eslint-disable max-len -->
  <div>
    <header class="py-4 lg:py-6 bg-primary-700 flex justify-center">
      <a class="sm:flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <img src="logo-parcelex-white.svg" class="h-8" alt="Logo Parcelex"/>
        <div class="uppercase tracking-widest font-light text-white">
          Para Negócios
        </div>
      </a>
    </header>
    <article class="w-full max-w-3xl mx-auto leading-loose py-9 px-6 lg:py-12 px-0">
      <h1 class="font-bold uppercase text-lg mb-6">
        CONTRATO DE PRESTAçãO DE SERVIçOS E OUTRAS AVENçAS
      </h1>

      <p>Pelo presente instrumento particular, as Partes abaixo qualificadas:</p>

      <p><span class="font-bold uppercase">PARCELEX CORRESPONDENTE BANCáRIO S.A.</span>, sociedade anônima, inscrita no CNPJ/ME sob o nº 14.054.623/0001-51, com sede na Av. Ataulfo de Paiva, nº 1.079, salas 707, 708 e 709, Leblon, CEP 22440-034, Cidade do Rio de Janeiro, Estado do Rio de Janeiro, neste ato representada na forma de seu Estatuto Social, doravante denominada simplesmente "<span class="underline">Companhia</span> ou <span class="underline">Parcelex"</span> e</p>

      <p><span class="font-bold uppercase">ESTABELECIMENTO;</span></p>

      <p>Sendo a Parcelex e o Estabelecimento, quando referidos isoladamente, serão também denominados como "<span class="underline">Parte"</span> e, quando em conjunto, como "<span class="underline">Partes</span>".</p>

      <p><span class="font-bold uppercase">CONSIDERANDO QUE:</span></p>

      <ol class="list-lower-roman">
        <li aria-level="1">
          A Parcelex tem como objeto o desenvolvimento, gerenciamento e administração de uma plataforma de financiamento para aquisição de produtos no âmbito do setor varejista, disponibilizada por meio da internet, de forma facilitada, ágil e com segurança (a "<span class="underline">Plataforma</span>"), atuando nesse contexto como correspondente bancário de instituições financeiras, nos termos da Resolução nº 3.954, de 24 de fevereiro de 2011, estas últimas responsáveis por formalizar as operações financeiras necessárias aos empréstimos contraídos por tomadores a partir da Plataforma;
        </li>
        <li aria-level="1">
          A Parcelex possibilita aos Estabelecimentos a venda de produtos e/ou serviços aos seus Usuários (conforme definido abaixo) através de boleto bancário de forma parcelada;
        </li>
        <li aria-level="1">
          O Estabelecimento tem como objeto a venda de produtos e/ou serviços online; e
        </li>
        <li aria-level="1">
          O Estabelecimento tem interesse em contratar os serviços prestados pela Parcelex de forma a oferecer aos seus Usuários mais uma opção de meio de pagamento eletrônico,
        </li>
      </ol>

      <p>Resolvem as Partes celebrar o presente Contrato de Prestação de Serviços e Outras Avenças ("<span class="underline">Contrato</span>") segundo os termos e condições a seguir dispostos:</p>

      <p><span class="font-bold uppercase">CLáUSULA PRIMEIRA - INTERPRETAçãO E DEFINIçõES</span></p>

      <p>1.1 - As Partes concordam que: (i) os cabeçalhos e títulos das cláusulas deste Contrato servem apenas para conveniência de referência e não limitarão ou afetarão o significado das cláusulas, parágrafos ou itens aos quais se aplicam; (ii) sempre que exigido pelo contexto, as definições contidas neste Contrato serão aplicadas tanto no singular quanto no plural e o gênero masculino incluirá o feminino e vice-versa; (iii) referências a quaisquer documentos ou outros instrumentos incluem todas as suas alterações, substituições e consolidações e respectivas complementações, salvo se expressamente disposto de forma diversa; (iv) salvo se de outra forma expressamente estabelecido neste Contrato, referências a capítulos, cláusulas, itens ou anexos aplicam-se a capítulos, cláusulas, itens e anexos deste Contrato; (v) salvo se de outra forma expressamente estabelecido neste Contrato, todas as referências a quaisquer Partes incluem seus sucessores e cessionários autorizados a qualquer título; (vi) a utilização dos termos "inclusive", "incluindo" e outros termos semelhantes no presente Contrato seguidos de qualquer declaração, termo ou matéria genérica não poderá ser interpretada de forma a limitar tal declaração, termo ou matéria aos itens ou matérias específicos inseridos imediatamente após tal palavra &mdash; bem como a itens ou matérias similares &mdash;, devendo, ao contrário, ser considerada como sendo referência a todos os outros itens ou matérias que poderiam razoavelmente ser inseridos no escopo mais amplo possível de tal declaração, termo ou matéria, e tais termos serão sempre lidos como se estivessem acompanhados do termo "exemplificativamente"; e (vii) os "<span class="italic">Consideranda</span>" deste Contrato são considerados como sendo parte integrante do presente Contrato.</p>

      <p>1.2 - As palavras, expressões e termos adiante indicados têm as seguintes definições:</p>

      <p>"<span class="underline">Chargeback</span>" - é o procedimento de contestação de débito pelo qual um Usuário não reconhece e/ou contesta, junto à Parcelex, uma despesa e/ou compra efetuada no Estabelecimento através da Plataforma.</p>

      <p>"<span class="underline">Informações Confidenciais</span>" - significam o conteúdo do Contrato e todas as informações fornecidas (seja de forma escrita ou oral, direta ou indiretamente) por uma Parte para a outra Parte, sejam antes ou depois da data de assinatura do Contrato e marcada, ou indicada, como "Confidencial" ou similar, ou cuja Parte que recebe entenda, de forma razoável, serem Informações Confidenciais devido à natureza das informações ou às circunstâncias dos serviços. As Informações Confidenciais incluem informações relacionadas ao fornecimento de informações sobre produtos e serviços, operações, clientes e prospectos, tecnologia, know-how, pesquisa e desenvolvimento, direitos sobre desenhos, segredos de mercado, oportunidades de mercado ou relações comerciais da Parte.</p>

      <p>"<span class="underline">Instituição Financeira</span>" - significa a instituição financeira parceira da Parcelex responsável por realizar as operações de empréstimo com os Usuários.</p>

      <p>"<span class="underline">Tecnologia Parcelex</span>" - significa qualquer software ou hardware de propriedade da Parcelex e qualquer outra tecnologia de propriedade da Parcelex que possibilite ao Estabelecimento a venda de produtos e/ou serviços aos seus Usuários através de boleto bancário de forma parcelada.</p>

      <p>"<span class="underline">Taxa de Transação</span>" Taxa cobrada sobre cada transação realizada através da Tecnologia Parcelex.</p>

      <p>"<span class="underline">Transação</span>" - significa todas e quaisquer aquisições de produtos e/ou serviços por parte dos Usuários no Estabelecimento através da Tecnologia Parcelex.</p>

      <p>"<span class="underline">Usuários</span>" - significa a pessoa física ou jurídica que deseje adquirir produtos e/ou serviços ofertados pelo Estabelecimento com o emprego da Tecnologia Parcelex para realização do pagamento.</p>

      <p><span class="font-bold uppercase">CLáUSULA SEGUNDA - OBJETO</span></p>

      <p>2.1 - De acordo com os termos e disposições do presente Contrato, a Parcelex colocará a disposição do Estabelecimento uma solução tecnológica que possibilitará ao Estabelecimento aceitar pagamentos efetuados pelos Usuários também cadastrados junto à Parcelex através de boletos bancários de forma parcelada.</p>

      <p>2.1.1 - O Estabelecimento estará subordinado, sem restrições, a todas as normas e condições deste Contrato e quaisquer outras condições e regras operacionais e de segurança a serem instituídas pela Parcelex, pela Instituição Financeira e/ou pelo Banco Central do Brasil, devendo observá-las e respeitá-las integralmente.</p>

      <p>2.2 - Nos termos do presente Contrato, a Parcelex atua como mera intermediadora de pagamentos entre o Estabelecimento e os Usuários, não sendo a Parcelex uma instituição financeira ou prestadora de serviços financeiros.</p>

      <p>2.2.1 - A Parcelex, sob sua única e exclusiva responsabilidade, poderá subcontratar terceiros ou realizar parcerias para a prestação de parte dos serviços, respondendo integralmente por tal ato.</p>

      <p>2.3 - O Estabelecimento declara-se ciente de que a Parcelex não poderá ser responsabilizada ou assumirá qualquer responsabilidade por falhas, erros, interrupções, mau funcionamento, atrasos ou lentidões que possam surgir na Plataforma, ainda que de responsabilidade da Parcelex ou de seus parceiros, não garantindo a manutenção de seus sistemas de forma ininterrupta, sem momentos de indisponibilidade ou lentidão, por se tratar de serviços de tecnologia.</p>

      <p>2.4 - O Estabelecimento declara e garante que será integralmente responsável pela veracidade, precisão e conformidade das informações e detalhes que vier a apresentar aos Usuários, com relação aos produtos e/ou serviços oferecidos, bem como pela efetiva conclusão da Transação e entrega do produto de acordo com os termos e condições informados, sendo o Estabelecimento o único responsável pela qualidade, quantidade, segurança, adequação, preço, prazo, entrega, funcionalidade e garantias destes produtos e/ou serviços.</p>

      <p>2.5 - A Parcelex não garante a autenticidade ou legalidade das Transações processadas através de sua Plataforma. O risco de fraude por uso não autorizado de identidade de um Usuário, ou a ilegalidade de uma Transação são inteiramente assumidos pelo Estabelecimento.</p>

      <p>2.6 - O Estabelecimento concorda que a Parcelex poderá compartilhar os dados pessoais dos Usuários com empresas parceiras da Parcelex, que se comprometam a utilizar as informações para os mesmos fins indicados na Política de Privacidade da Parcelex.</p>

      <p><span class="font-bold uppercase">CLáUSULA TERCEIRA - DIREITOS E OBRIGAçõES DAS PARTES</span></p>

      <p>3.1 - Constituem direitos e obrigações da <span class="font-bold">Parcelex</span>, nos termos deste Contrato:</p>

      <ol class="list-lower-roman">
        <li>
          Prestar suporte ao Estabelecimento na utilização da sua Plataforma;
        </li>
        <li>
          Comunicar ao Estabelecimento qualquer situação que possa afetar a continuidade deste Contrato ou a sua fiel execução em todos seus termos e condições;
        </li>
        <li>
          Realizar manutenções preventivas e corretivas referentes à Plataforma no ambiente operacional do Estabelecimento para garantir o bom funcionamento da tecnologia;
        </li>
        <li>
          é facultado à Parcelex, a qualquer tempo, alterar, adequar ou remodelar o conteúdo, layout, funcionalidades e ferramentas relacionadas à Plataforma;
        </li>
        <li>
          A Parcelex poderá, a qualquer tempo e a seu exclusivo critério, com o intuito de minimizar riscos de falhas humanas ou o uso indevido da Plataforma, impor limites e/ou restrições de compra aos Usuários;
        </li>
        <li>
          Cumprir e fazer cumprir os preceitos legais estabelecidos na Lei 13.709/2018 (Lei Geral de Proteção de Dados) e na Lei 12.965/14 (Marco Civil da Internet) através de políticas e diretrizes definidas pela Parcelex para o tratamento de dados pessoais dos Usuários em conformidade com os preceitos definidos nestas leis; e
        </li>
        <li>
          Observar, respeitar e cumprir todas e quaisquer leis, regulamentos e instruções aplicáveis às suas atividades, incluindo, mas não se limitando, as regras e exigências determinadas pelo Banco Central do Brasil e pela legislação e regulamentação aplicável.
        </li>
      </ol>

      <p>3.2 - Constituem direitos e obrigações do <span class="font-bold">Estabelecimento</span>, nos termos deste Contrato:</p>

      <ol class="list-lower-roman">
        <li>Implementar a Plataforma da Parcelex (via tag ou API) em seu site e manter a Plataforma da Parcelex em pleno funcionamento, devidamente integrada em tempo integral ao site do Estabelecimento, de forma a permitir a venda de produtos e/ou serviços através da Plataforma;</li>

        <li>Cumprir com as obrigações previstas neste Contrato e realizar os pagamentos devidos sob este Contrato nas formas e nos respectivos prazos e datas de vencimento;</li>

        <li>Observar, respeitar e cumprir todas e quaisquer leis, regulamentos e instruções aplicáveis às suas atividades, incluindo, mas não se limitando, as regras e exigências determinadas pelo Banco Central do Brasil e pela legislação e regulamentação aplicável;</li>

        <li>Cumprir e fazer cumprir os preceitos legais estabelecidos na Lei 13.709/2018 (Lei de Proteção de Dados) e na Lei 12.965/14 (Marco Civil da Internet), responsabilizando-se integralmente, e assumindo para si qualquer responsabilização que a Parcelex possa vir a sofrer em virtude da não conformidade com os preceitos legais por parte do Estabelecimento;</li>

        <li>Realizar as adequações técnicas solicitadas pela Parcelex, tais como homologações e atualizações de sistemas, nos prazos solicitados pela Parcelex, com o intuito de garantir a segurança e a eficiência da Plataforma;</li>

        <li>Solicitar atendimento imediato a problemas operacionais relacionados às soluções tecnológicas da Plataforma;</li>

        <li>Esclarecer, sempre que necessário, aos Usuários que a Parcelex é uma empresa prestadora de serviços de facilitação de pagamentos, não tendo qualquer responsabilidade sobre a venda de produtos e/ou serviços;</li>

        <li>Responsabilizar-se exclusivamente pelas informações transmitidas à Plataforma ou através dela, isentando a Parcelex pelos conteúdos gerados pelo Estabelecimento; e</li>

        <li>Comprometer-se a não utilizar o serviço da Plataforma para transações/operações relacionadas a (i) atividades ilegais, tais como bestialidade, pedofilia, tráfico de droga e lavagem de dinheiro; (ii) armas de fogo e jogos de azar, inclusive bingo e apostas em geral; (iii) produtos pendentes de homologação de órgãos governamentais, e (iv) outras transações que forem vedadas por lei.</li>

      </ol>

      <p>3.2.1 - As atividades mencionadas na Cláusula 3.2(ix) acima têm caráter meramente exemplificativo, podendo ser estabelecidas outras atividades vedadas e entendidas como inadequadas ou ilegais, a exclusivo critério da Parcelex.</p>

      <p><span class="font-bold uppercase">CLáUSULA QUARTA - PAGAMENTO DAS TRANSAçõES</span></p>

      <p>4.1 - A compra de produtos e/ou serviços do Estabelecimento por meio da Plataforma será realizada pelos Usuários mediante a contratação de uma Cédula de Crédito Bancária a ser emitida pelo Usuário em favor da Instituição Financeira.</p>

      <p>4.2 - Uma vez efetuada a compra de produtos e/ou serviços do Estabelecimento por meio da Plataforma, a Instituição Financeira pagará ao Estabelecimento, no prazo de até 30 (trinta) dias úteis, o valor do produto e/ou serviço adquirido pelo Usuário, para conta bancária indicada pelo Estabelecimento, descontado da Taxa de Transação especificada no item 5.1 deste Contrato.</p>

      <p>4.2.1 - Na hipótese de a data prevista para o crédito do valor da Transação ser considerada feriado ou em dia de não funcionamento bancário na praça de compensação da conta bancária do Estabelecimento, o pagamento será realizado no primeiro dia útil subsequente.</p>

      <p>4.3 - O Estabelecimento não poderá ceder eventuais créditos que detenha em virtude deste Contrato sem a prévia e escrita autorização da Parcelex, sob pena de ineficácia da cessão, para todos os fins legais.</p>

      <p>4.4 - O Estabelecimento terá acesso às Transações através dos relatórios disponibilizados pela Parcelex, podendo visualizar o extrato de todas as movimentações realizadas no Estabelecimento através da Plataforma.</p>

      <p>4.4.1 - A Parcelex disponibilizará acesso às Transações e pagamentos dos últimos 12 (doze) meses, sendo que após este prazo a Parcelex não se responsabiliza pela manutenção das informações, cabendo ao Estabelecimento o controle e arquivo próprio das movimentações anteriores.</p>

      <p>4.5 - O Estabelecimento terá o prazo de 10 (dez) dias para apontar qualquer eventual divergência ou incorreção em relação a qualquer um dos valores pagos pela Instituição Financeira em sua conta bancária.</p>

      <p>4.5.1 - O prazo a que se refere o caput começa a contar da data do pagamento ou da data prevista para pagamento pela Instituição Financeira.</p>

      <p>4.5.2 - Decorrido o prazo estipulado no parágrafo anterior, o Estabelecimento dará a plena e definitiva quitação à Parcelex e a Instituição Financeira.</p>

      <p><span class="font-bold uppercase">CLáUSULA QUINTA - REMUNERAçãO</span></p>

      <p>5.1 - Em contrapartida à prestação dos serviços de tecnologia, gestão de pagamentos e à licença de uso da Tecnologia Parcelex, será cobrado o percentual de 4% (quatro porcento) de Taxa MDR por Transação realizada no Estabelecimento.</p>

      <p>5.2 - A Parcelex poderá, a seu exclusivo critério, reajustar os valores de sua remuneração, disponibilizando-as através de qualquer meio de comunicação eficaz e aceitável pelas Partes, com pelo menos 10 (dez) dias de antecedência de sua aplicação. Em caso de recusa do Estabelecimento às novas condições, este Contrato será rescindido no prazo de 15 (quinze) dias posterior à informação dos novos valores.</p>

      <p>5.3 - A Parcelex poderá, a seu exclusivo critério, conceder ao Estabelecimento condições promocionais para utilização do serviço ora prestado, tais como descontos, reduções temporárias de remuneração e períodos de testes. A oferta de condições promocionais será considerada mera liberalidade da Parcelex, que as poderá, a qualquer tempo, alterar e retirar, sem que fique caracterizada novação ou renúncia aos direitos ora contratados.</p>

      <p><span class="font-bold uppercase">CLáUSULA SEXTA - TERMOS DE USO</span></p>

      <p>6.1 - O Estabelecimento deverá praticar preços iguais aos praticados nas vendas realizadas em dinheiro, cheque, cartões ou outros meios de pagamento, sem acréscimo de qualquer encargo ou taxa de qualquer natureza, oferecendo aos Usuários as mesmas condições e/ou vantagens promocionais oferecidas a quaisquer outros meios ou formas de pagamento, salvo com autorização expressa da Parcelex.</p>

      <p>6.2 - O Estabelecimento está ciente de que se atingir ou ultrapassar os índices de contestação de Transações, conforme os limites estabelecidos pela Parcelex, a Parcelex poderá suspender temporariamente ou de forma definitiva o uso da Plataforma e não efetivar novas Transações, bloqueando os acessos tecnológicos do Estabelecimento.</p>

      <p>6.2.1 - O índice limite de contestação de Transações para efeitos deste Contrato será de 1% (um porcento) do volume de Transações realizadas em um mês pelo Estabelecimento. A Parcelex, a seu exclusivo critério, poderá alterar o índice de contestação de Transações a qualquer tempo.</p>

      <p>6.3 - Caso haja indício de ilicitude, fraude ou violação ao presente Contrato por parte do Estabelecimento, a Parcelex poderá solicitar à Instituição Financeira a suspensão da realização de qualquer pagamento ou movimentação, até que se esclareça a situação.</p>

      <p>6.4 - O Estabelecimento deve cumprir suas obrigações legais e contratuais decorrentes da Transação, zelando pela alta qualidade, bem como pela segurança e satisfação dos Usuários, sendo o exclusivo responsável por solucionar, diretamente com os Usuários, toda e qualquer eventual controvérsia sobre as características, qualidade, quantidade, propriedades, origem, preço, funcionamento, garantias, defeitos e/ou avarias dos bens e produtos adquiridos e/ou serviços prestados, incluindo casos de defeito ou devolução, problemas de entrega etc. A Parcelex e a Instituição Financeira são isentas de quaisquer responsabilidades convencionais ou legais em relação aos fatos mencionados nesta Cláusula, inclusive com relação ao Código de Proteção e Defesa do Consumidor.</p>

      <p>6.4.1 - Na hipótese de ajuizamento de processos judiciais e/ou administrativos em face da Parcelex, relativamente a quaisquer atividades do Estabelecimento, iniciados a qualquer tempo, o Estabelecimento se obriga a assumir de imediato a responsabilidade pelas obrigações exigidas ou reivindicadas nos referidos processos, isentando a Parcelex de qualquer responsabilidade, podendo a Parcelex, neste caso, requerer a citação do Estabelecimento para assumir o polo passivo na lide, devendo o Estabelecimento indenizar integralmente a Parcelex por quaisquer despesas decorrentes desses processos.</p>

      <p>6.4.2 - O Estabelecimento obriga-se a ressarcir a Parcelex de todos os valores comprovadamente despendidos em referidas ações judiciais ou processos administrativos, bem como a prestar garantia e/ou adiantar pagamentos a serem efetuados pela Parcelex, em razão de eventuais condenações, no prazo de 48 (quarenta e oito) horas a contar da solicitação pela Parcelex.</p>

      <p>6.5 - O Estabelecimento desde já autoriza expressamente a Parcelex a lhe descontar de eventuais valores devidos pela Instituição Financeira ao Estabelecimento quaisquer despesas incorridas para o cumprimento de ordem de terceiro com relação ao Estabelecimento, incluindo, sem limitar-se a, o atendimento de ofícios judiciais, bloqueios, penhoras, arrestos e custos com advogados para a defesa de seus interesses.</p>

      <p>6.6 - O Estabelecimento compromete-se a ressarcir a Parcelex, de todo e qualquer prejuízo por ela sofrido, em virtude de atos por ele praticados.</p>

      <p><span class="font-bold uppercase">CLáUSULA SéTIMA - CONTESTAçãO E ESTORNO DAS TRANSAçõES</span></p>

      <p>7.1 - A Parcelex se resguarda ao direito de requerer que a Instituição Financeira não efetue o repasse do crédito de Transações para o Estabelecimento quando houver contestação, cancelamento, Chargeback ou qualquer outra hipótese de estorno das Transações ou se ficar verificado que há indícios de que a referida Transação é irregular, viciada ou de qualquer maneira violar os termos e condições desse Contrato, além da legislação aplicável.</p>

      <p>7.1.1 - O valor das Transações descritas nesta cláusula deverá ser integralmente ressarcido à Instituição Financeira e/ou à Parcelex, podendo ser compensado inclusive em razão da remuneração prevista neste Contrato.</p>

      <p>7.2 - O Estabelecimento será exclusivamente responsável por qualquer hipótese de cancelamento, estorno ou Chargeback. Dessa forma, o Estabelecimento deverá solucionar diretamente com o Usuário toda e qualquer controvérsia sobre os produtos e/ou serviços fornecidos, incluindo casos de defeito ou devolução, problemas na entrega, etc.</p>

      <p>7.3 - Na hipótese de contestação de Transações, o Estabelecimento (i) será responsável pelo reembolso do valor correspondente à Transação contestada à Instituição Financeira e/ou à Parcelex; (ii) prestará à Parcelex informações disponíveis para efetivar a contestação da Transação; e (iii) deverá arcar com os custos da Transação e da contestação, incluindo a remuneração devida à Instituição Financeira e/ou à Parcelex.</p>

      <p>7.3.1 - O Estabelecimento deverá fornecer a Parcelex toda a documentação de comprovação de entrega de produtos ou da devida prestação de serviços no prazo de 5 (cinco) dias úteis para afastar a contestação por Chargeback ficando compreendido que a falta da entrega no prazo determinado compreende à não entrega de produto/serviço.</p>

      <p>7.4 - Na hipótese de cancelamento ou estorno da Transação, se o pagamento da Transação já houver sido realizado pela Instituição Financeira ao Estabelecimento, o Estabelecimento deverá restituir o respectivo valor à Instituição Financeira e/ou à Parcelex no prazo de 5 (cinco) dias úteis contados do respectivo cancelamento ou estorno, podendo a Instituição Financeira compensá-lo com eventuais créditos devidos ao Estabelecimento relativos a este Contrato.</p>

      <p>7.5 - Em caso de cancelamento, estorno ou qualquer devolução de valores devidos à Instituição Financeira e/ou à Parcelex a qualquer título, o referido montante deverá ser restituído pelo Estabelecimento, atualizado positivamente pelo IGP-M/FGV desde a data do repasse ou a partir de quando se tornou exigível, mais juros de 1% (um por cento) ao mês ou fração pro-rata, acrescido dos encargos operacionais e perdas e danos incorridos.</p>

      <p>7.6 - Se a Parcelex for autuada, notificada, citada, intimada ou condenada em razão do não cumprimento, em época própria, de qualquer obrigação atribuível ao Estabelecimento, seja de natureza fiscal, trabalhista, previdenciária ou de qualquer outra espécie, assistir-lhe-á o direito de solicitar a Instituição Financeira a retenção, a partir do recebimento da autuação, notificação, citação ou da intimação, a quantia referente à contingência calculada de acordo com os parâmetros estabelecidos pela Parcelex. Este valor será restituído ao Estabelecimento nos casos em que a mesma satisfizer a respectiva obrigação ou a Parcelex for excluída do polo passivo, mediante decisão irrecorrível.</p>

      <p><span class="font-bold uppercase">CLáUSULA OITAVA - PROPRIEDADE INTELECTUAL E USO DE MARCA E LOGO</span></p>

      <p>8.1 - O site e a Plataforma da Parcelex, bem como as marcas, logotipos e demais conteúdos utilizados no desempenho dos serviços objeto deste Contrato são de propriedade da Parcelex e estão protegidos pelas normas de direitos autorais, direitos de marca e de propriedade intelectual e demais normas aplicáveis, sendo vedada a sua reprodução, cópia ou qualquer outra forma de utilização comercial ou divulgação não autorizada.</p>

      <p>8.2 - O Estabelecimento não terá nenhum direito, título ou interesse, expresso ou tácito, em relação aos conteúdos do site e da Plataforma da Parcelex e a sua utilização, e não deverá, em nenhum momento, após a vigência deste Contrato, assumir ou pleitear nenhum direito de explorar, vender ou colocar à venda os conteúdos, sem a expressa autorização de seus detentores.</p>

      <p>8.3 - As Partes poderão fazer uso da marca e logotipo da outra em sua própria plataforma digital, para fins de divulgação do serviço oferecido pela Parcelex, bem como em campanhas de mídia impressa, rádio, televisão, internet, fóruns, entre outros, desde que sejam cumpridas as regras de uso da marca de cada Parte. Sendo assim, cada Parte deverá encaminhar, previamente à efetiva publicação, o material a ser divulgado para a outra Parte, para fins de revisão e aprovação expressa da divulgação e das regras que serão utilizadas na exposição da marca e logotipo. Caso dentro do prazo de 5 (cinco) dias, a contar da notificação, não haja manisfestação em contrário da outra Parte, será considerada a sua aprovação tácita.</p>

      <p>8.4 - A utilização indevida por parte do Estabelecimento de tais propriedades intelectuais ensejará à Parcelex o direito de resolver imediatamente o presente Contrato, sem prejuízo da tomada de medidas judiciais e extrajudiciais cabíveis, bem como das reparações e indenizações aplicáveis.</p>

      <p>8.5 - Após o término deste Contrato por qualquer motivo, as Partes deverão cessar o uso da marca e logotipo da outra de forma irrevogável, irretratável e imediata.</p>

      <p><span class="font-bold uppercase">CLáUSULA NONA - CONFIDENCIALIDADE</span></p>

      <p>9.1 - Durante o período deste Contrato e após 3 (três) anos de seu término, cada Parte irá (i) utilizar as Informações Confidenciais da outra Parte somente com base no Contrato; (ii) somente fornecerá Informações Confidenciais para terceiros com o consentimento, por escrito, da outra Parte, com exceção de seus conselheiros profissionais e auditores; e (iii) evitar o uso não-autorizado ou fornecimento de Informações Confidenciais utilizando, no mínimo o mesmo grau de cuidado exercido para proteger suas próprias Informações Confidenciais, mas, em todo caso, utilizando nada mais que um grau razoável de cuidado.</p>

      <p>9.2 - Não serão consideradas como Informações Confidenciais, aquelas que: (i) sejam de prévio conhecimento da outra Parte; (ii) pertenciam ao domínio público em data anterior à data da divulgação; (iii) tornaram-se parte do domínio público sem culpa comprovada das Partes; (iv) forem, a partir da assinatura deste Contrato, obtidas por qualquer das Partes de boa-fé de um terceiro que não tenha recebido tal informação de outro que sabidamente estava obrigado a manter segredo, ou (v) forem requisitadas através de ordem judicial por uma autoridade governamental competente, sendo neste caso necessário que a Parte receptora notifique com antecedência a Parte informante do pedido, além de utilizar esforços comercialmente razoáveis para limitar a extensão de tal abertura. As Partes deverão tratar e manter as Informações Confidenciais reveladas pela outra Parte com confiança e utilizar tais Informações Confidenciais somente para o propósito deste Contrato, tomando todas as precauções razoáveis para manter o segredo de tais Informações Confidenciais.</p>

      <p>9.3 - As Partes não revelarão qualquer Informação Confidencial a terceiros sem obter prévia aprovação para tal revelação pela outra Parte. Cada uma das Partes reconhece que a revelação desautorizada das Informações Confidenciais recebidas da outra Parte acarretará dano irreparável e prejuízo substancial à outra, de forma que cada uma das Partes deverá envidar seus melhores esforços e tomar todas as medidas cabíveis e necessárias, judiciais ou extrajudiciais, a fim de minimizar os riscos oriundos da revelação das Informações Confidenciais, bem como a fim de impedir que tais informações sejam reveladas.</p>

      <p>9.4 - Não obstante o disposto acima, a Parcelex está autorizada pelo Estabelecimento, sem configurar descumprimento da cláusula de confidencialidade, a (i) prestar às autoridades competentes como, por exemplo, Banco Central do Brasil, Receita Federal, Secretarias das Fazendas Estaduais, Secretarias de Arrecadação Municipais, Conselho de Controle de Atividades Financeiras, Polícia Federal etc., todas as informações que forem solicitadas com relação ao Estabelecimento, Transações e operações por ela executadas sob este Contrato; (ii) comunicar ao Conselho de Controle de Atividades Financeiras as Transações que possam estar configuradas na Lei 9.613/98 e demais normas relativas à repressão à lavagem de dinheiro; e (iii) prestar informações a Instituição Financeira e as entidades que se destinem a controlar garantias que envolvam recebíveis.</p>

      <p><span class="font-bold uppercase">CLáUSULA DéCIMA - PRAZO E RESCISãO</span></p>

      <p>10.1 - O presente Contrato entra em vigor na data de sua assinatura e permanecerá em vigor por prazo indeterminado.</p>

      <p>10.2 - Este Contrato poderá ser resilido, por qualquer das Partes, a qualquer momento, mediante notificação com antecedência de 60 (sessenta) dias.</p>

      <p>10.2.1 - A resilição ocorrerá livre de direitos indenizatórios, ônus, encargos ou penalidades, ressalvadas as obrigações contratuais pendentes, que deverão ser cumpridas até o seu término, na forma deste Contrato.</p>

      <p>10.3 - Este Contrato poderá ser rescindido imediatamente por qualquer das Partes nos seguintes casos:</p>

      <ol class="list-lower-roman">
        <li aria-level="1">
          caso qualquer das Partes viole ou descumpra os termos e condições deste Contrato e deixe de sanar integralmente este descumprimento no prazo de 15 (quinze) dias, a contar do recebimento de notificação enviada pela outra Parte neste sentido;
        </li>
        <li aria-level="1">
          caso seja feito qualquer pedido de falência, recuperação judicial ou extrajudicial, ou liquidação extrajudicial da outra Parte;
        </li>
        <li aria-level="1">
          caso ocorra um fato que, por sua natureza e gravidade, incidam sobre a confiabilidade e moralidade de uma das Partes ou que seja suscetível de causar danos ou comprometer, mesmo que indiretamente, a imagem da outra Parte;
        </li>
        <li aria-level="1">
          caso haja impasse entre as Partes na definição de ajustes ou alterações ao presente Contrato;
        </li>
        <li aria-level="1">
          caso haja o exercício de atividades consideradas ilegais ou ilícitas pelo Estabelecimento;
        </li>
        <li aria-level="1">
          caso ocorra qualquer conduta dolosa ou culposa praticada pelo Estabelecimento que possa causar excesso de contestações, cancelamentos ou Chargebacks das Transações efetuadas; ou
        </li>
        <li aria-level="1">
          caso as Partes, por acordo mútuo, decidam rescindir este Contrato.
        </li>
      </ol>

      <p>10.3.1 - Caso a rescisão do Contrato ocorra por culpa do Estabelecimento, fica desde já estabelecido que o acesso a Plataforma será imediatamente bloqueado, podendo a Parcelex solicitar a Instituição Financeira a retenção dos créditos do Estabelecimento, pelo prazo que julgar conveniente, de forma a garantir seus direitos, assim como de terceiros que possam ter sido lesados pelo Estabelecimento, sem prejuízo de outras medidas legais que entender necessárias.</p>

      <p>10.4 - Quando do término deste Contrato, qualquer que seja a razão, as Partes deverão imediatamente: (i) cessar a utilização da propriedade intelectual da outra Parte, incluindo, mas não se limitando a, uso de marcas e logos; e (ii) certificar-se de que todos os links instalados, ou logins de acesso, pertinentes a este Contrato foram removidos.</p>

      <p>10.5 - Não obstante o acima disposto, as obrigações previstas na Cláusula 9 deste Contrato permanecerão válidas e eficazes após o término deste Contrato.</p>

      <p><span class="font-bold uppercase">CLáUSULA DéCIMA PRIMEIRA - EXCLUSIVIDADE</span></p>

      <p>11.1 - Fica certo e ajustado entre as Partes que, durante o prazo de vigência deste Contrato, o Estabelecimento não poderá contatar, negociar, prospectar, discutir, contratar, prestar informações, comprometer-se ou de qualquer outra forma se associar com qualquer terceiro com a finalidade de oferecer aos seus Usuários tecnologia similar à Tecnologia Parcelex.</p>

      <p><span class="font-bold uppercase">CLáUSULA DéCIMA SEGUNDA - DECLARAçõES</span></p>

      <p>12.1 - Cada Parte declara e garante à outra que as afirmações a seguir são verdadeiras em relação à própria Parte na celebração deste Contrato e assim permanecerão durante todo o prazo de vigência deste:</p>

      <ol class="list-lower-roman">
        <li aria-level="1">
          possui plena capacidade e legitimidade para celebrar o presente Contrato e cumprir todas as obrigações aqui assumidas, tendo tomado todas as medidas de natureza societária e outras eventualmente necessárias para autorizar a sua celebração, para implementar todas as operações nele previstas e cumprir todas as obrigações nele assumidas;
        </li>
        <li aria-level="1">
          a celebração deste Contrato e o cumprimento das obrigações pelas Partes (a) não violam qualquer disposição contida nos seus documentos societários; (b) não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva Parte esteja vinculada; (c) estão devidamente autorizadas, nos termos dos seus atos constitutivos em vigor; e (d) não constituem uma violação a, nem estão em conflito com qualquer contrato do qual seja parte;
        </li>
        <li aria-level="1">
          este Contrato é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível contra cada Parte, de acordo com os seus termos;
        </li>
        <li aria-level="1">
          atua em conformidade com todas as leis, regulamentações, manuais, políticas e quaisquer disposições relacionadas ao combate e prevenção à corrupção, à lavagem de dinheiro e ao financiamento do terrorismo, incluindo, mas não se limitando, a legislação brasileira aplicável, UK Bribery Act e Foreign Corrupt Practices Act (FCPA); e
        </li>
        <li aria-level="1">
          não realizou, não realiza e não realizará quaisquer atos ou práticas que, direta ou indiretamente, envolvam oferecimento, promessas, suborno, extorsão, autorização, solicitação, aceite, pagamento, entrega ou qualquer outro ato relacionado a vantagem pecuniária indevida ou qualquer outro favorecimento ilegal em desconformidade com a legislação mencionada acima e aplicável.
        </li>
      </ol>

      <p><span class="font-bold uppercase">CLáUSULA DéCIMA TERCEIRA - DISPOSIçõES GERAIS</span></p>

      <p>13.1 - O presente Contrato, juntamente com seus Anexos, constituem o entendimento único e integral entre as Partes com relação ao seu objeto e substituem todos os contratos, acordos, cartas de intenção e entendimentos anteriores entre as Partes com relação a esse objeto, e qualquer alteração só será válida através da assinatura por ambas as Partes de um termo aditivo.</p>

      <p>13.2 - Não se estabelece por força deste Contrato, qualquer vínculo empregatício entre as Partes com relação ao pessoal empregado para a execução dos serviços ora contratados, correndo por conta exclusiva de cada Parte, única responsável como empregadora, todas as despesas com seu próprio pessoal e empregados, inclusive os encargos decorrentes da legislação trabalhista, previdenciária e de acidentes de trabalho, obrigando-se assim cada Parte no cumprimento de todas as obrigações legais, quer quanto à remuneração dos seus empregados, quer quanto aos demais encargos.</p>

      <p>13.3 - As Partes são pessoas jurídicas independentes, e nenhuma disposição no presente Contrato deverá ser interpretada no sentido de sugerir a existência de um grupo econômico, de uma joint venture, ou qualquer tipo de participação societária, relação de emprego ou sociedade entre as Partes ou em nome das mesmas.</p>

      <p>13.4 - A tolerância das Partes entre si quanto a eventuais descumprimentos de disposições do presente Contrato não constituirá renúncia aos direitos que são mutuamente ora conferidos, nem poderá ser tida como novação, permanecendo, em qualquer hipótese, plenamente íntegras as cláusulas deste Contrato, como se não tivesse havido qualquer tolerância.</p>

      <p>13.5 - Nenhuma das Partes poderá ceder ou transferir a qualquer título seus direitos ou obrigações sob este Contrato sem a prévia e expressa anuência da outra Parte. Não obstante o acima disposto, as Partes poderão ceder e transferir seus direitos e obrigações decorrentes do presente Contrato a qualquer outra sociedade controladora, controlada, sob o mesmo controle ou pertencente ao seu grupo econômico, mediante notificação enviada por escrito a outra Parte, ficando a cessionária automaticamente sub-rogada em todos os direitos e obrigações do cedente.</p>

      <p>13.6 - Cada uma das Partes é responsável diretamente, nos termos da legislação aplicável, pelo pagamento de todos os tributos e taxas incidentes no presente Contrato.</p>

      <p>13.7 - Qualquer notificação exigida consoante o presente Contrato será feita por escrito, através de correio eletrônico ou carta, mediante o aviso de recebimento, sendo válidas a partir da data de recebimento.</p>

      <ol class="list-lower-roman">
        <li>
          Se para a Parcelex:<br>
          Email: comercial@parcelex.com.br<br>
          Endereço: Rua Voluntários da Pátria, 89, sala 701 - CEP: 22270-000 - Rio de Janeiro - RJ
        </li>
        <li aria-level="1">
          Se para o Estabelecimento:<br>
          Para: [__________]<br>
          Email: <a href="mailto:rita.pfroes@genesisgroup.com.br"> [_________] </a><br>
          Endereço: <a href="mailto:rita.pfroes@genesisgroup.com.br"> [__________] </a>
        </li>
      </ol>

      <p>13.7.1 - Existindo alteração nos dados para entrega das correspondências, a Parte deve comunicar a outra imediatamente.</p>

      <p>13.7.2 Qualquer comunicação ao mercado a respeito da existência ou do objeto deste Contrato será previamente acordada entre as Partes.</p>

      <p>13.8 - Todas as obrigações assumidas neste Contrato possuem caráter irrevogável e irretratável, sendo que as Partes se obrigam por si e seus sucessores a cumprir, sem restrições, todos os termos e condições do presente Contrato e de seus Anexos.</p>

      <p>13.9 - Se a qualquer tempo, qualquer termo ou disposição deste Contrato for considerado ilegal, inválida ou ineficaz, no todo ou em parte, por força de lei, esse termo ou disposição será considerado como não constituindo parte deste Contrato, não devendo a exequibilidade do restante deste Contrato ser afetada.</p>

      <p>13.10 - Em caso de qualquer conflito entre os termos deste Contrato e qualquer Anexo, prevalecerão os termos do Contrato.</p>

      <p>13.11 - Este Contrato constitui título executivo extrajudicial, nos termos do art. 784, inciso II e seguintes, da Lei nº 13.105, de 16 de março de 2015 (Código de Processo Civil).</p>

      <p>13.12 - O presente Contrato é regido pelas leis da República Federativa do Brasil e as Partes elegem o foro central da comarca do Rio de Janeiro, Estado do Rio de Janeiro, para dirimir as dúvidas ou controvérsias oriundas deste Contrato, com exclusão de qualquer outro por mais privilegiado que seja.</p>

      <p class="text-center">Rio de Janeiro, RJ.</p>

      <div class="pt-9">
        <div class="h-px bg-black w-full max-w-md mx-auto"></div>
        <p class="text-center"><span class="font-bold uppercase text-sm tracking-wide">PARCELEX CORRESPONDENTE BANCáRIO S.A.</span></p>
      </div>

      <div class="pt-9">
        <div class="h-px bg-black w-full max-w-md mx-auto"></div>
        <p class="text-center"><span class="font-bold uppercase text-sm tracking-wide">ESTABELECIMENTO</span></p>
      </div>
    </article>
    <footer class="text-center text-gray-500 py-4 bg-gray-100">
      &copy; Parcelex - {{ actualDate }}
    </footer>
  </div>
</template>

<script>
export default {
  name: 'terms-and-conditions',
  metaInfo: {
    title: 'Termos e Condições da Central Parcelex',
  },
  computed: {
    actualDate() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="postcss">
p {
  @apply mb-4;
}
ol {
  @apply pl-6 mb-4;
}
ol li {
  @apply mb-3 last:mb-0;
}
.list-lower-roman {
  list-style-type: lower-roman;
}
</style>
